<template>
  <v-container>

  </v-container>
</template>

<script lang="js">
import eventBus from '@/eventBus.js';

export default {
  name: 'Home',
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
    ]);
  },
};
</script>
